@import './definitions.scss';
@import './mixins.scss';

html,
body {
    background-color: $color-primary-bg;
    overscroll-behavior: none;
}

[hidden],
.hidden {
    display: none !important;
}

.dashboard-scroll-y {
    overflow-y: scroll;
}

.clear-fix {
    @include clear-fix;
}

.no-scroll {
    @include no-scroll;
}

.no-select {
    @include no-select;
}

.dragging {
    cursor: default !important;
    @extend .no-select;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    color: $color-input;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-nowrap {
    white-space: nowrap;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.text-blue {
    color: $color-primary-blue;
}

.ml-32 {
    margin-left: 32px;
}

.mr-32 {
    margin-right: 32px;
}

.mt-32 {
    margin-top: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.ml-24 {
    margin-left: 24px;
}

.mr-24 {
    margin-right: 24px;
}

.mt-24 {
    margin-top: 24px;
}

.mb-24 {
    margin-bottom: 24px;
}


.ml-16 {
    margin-left: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ml-8 {
    margin-left: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.mb-8 {
    margin-bottom: 8px;
}
