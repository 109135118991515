@import './definitions.scss';

.input,
.textarea {
    display: inline-flex;
    color: $color-input;
    font-weight: $font-weight-semi-bold;
    text-align: left;
    border-radius: $control-primary-radius;
    border: $control-border-size solid $color-input-deco-color;
    box-shadow: $control-box-shadow;
    background-color: #fff;

    &:focus {
        box-shadow: 0 0 0 5px #c0dafa;
        border-color: $color-primary-blue;
    }

    &,
    &:focus {
        transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out;
    }

    &.ng-invalid:focus,
    &.ng-touched.ng-invalid,
    .form_show-errors &.ng-invalid {
        border-color: $color-input-error-borders;
    }

    fieldset[disabled] &,
    &[readonly],
    &[readonly]:focus,
    &[disabled],
    &[disabled]:focus {
        cursor: default;
    }

    fieldset[disabled] &,
    &[disabled],
    &[disabled]:focus {
        opacity: 0.7;
        pointer-events: none;
        box-shadow: none;
        transition: none;
    }

    &::placeholder               { color: $color-input-placeholder; }
    &::-moz-placeholder          { color: $color-input-placeholder; }
    &::-webkit-input-placeholder { color: $color-input-placeholder; }
    &::-ms-input-placeholder     { color: $color-input-placeholder; }
    &:-ms-input-placeholder      { color: $color-input-placeholder; }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }

    &_small {
        $height: 30px;
        $font-size: 12px;
        $line-height: ($font-size + 4px);
        $base-y-padding: floor(($height - $line-height - ($control-border-size * 2)) / 2);

        font-size: $font-size;
        line-height: $line-height;
        height: $height;
        padding: $base-y-padding 8px;
        border-radius: 4px;
    }

    &_regular {
        $font-size: 14px;
        $line-height: ($font-size + 4px);
        $base-y-padding: floor(($control-height-regular - $line-height - ($control-border-size * 2)) / 2);

        font-size: $font-size;
        line-height: $line-height;
        height: $control-height-regular;
        min-width: $control-height-regular * 2;
        padding: $base-y-padding 12px;
    }

    &_large {
        font-size: 14px;
        line-height: 20px;
        height: 46px;
        padding: 13px 18px;
    }

    &_no-border_tr {
        border-top-right-radius: 0;
    }

    &_no-border_br {
        border-bottom-right-radius: 0;
    }

    &_no-border_bl {
        border-bottom-left-radius: 0;
    }

    &_no-border_tl {
        border-top-left-radius: 0;
    }
}

.textarea {
    $font-size: 14px;
    $line-height: ($font-size + 4px);
    $base-y-padding: floor(($control-height-regular - $line-height - ($control-border-size * 2)) / 2);

    font-size: $font-size;
    line-height: $line-height;
    padding: $base-y-padding 12px;
    resize: vertical;
}
